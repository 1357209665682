import logo from './headshot.jpeg';
import './App.css';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="app-logo" alt="logo" />
        <h1 className="text-3xl font-bold py-12">
          Chris Spada
        </h1>
        <div className="text-xl max-w-lg">
          Full-stack software engineer @ <a className="underline" href="https://www.inclined.com">Inclined</a>.
        </div>
      </header>
      <ul className="resources-list mt-5">
        <li className="mt-2 px-2 py-1 underline">
          <a href="https://www.linkedin.com/in/spadachris/">LinkedIn</a>
        </li>
        <li className="mt-2 px-2 py-1 underline">
          <a href="https://www.github.com/spada-chris">GitHub</a>
        </li>
        <li className="mt-2 px-2 py-1 underline">
          <a href="https://chromewebstore.google.com/detail/github-pr-enhancer/gjhpmbgnmdlkolpbphpjcppoonkfdnmo">GitHub PR Enhancer</a>
        </li>
      </ul>
    </div>
  );
}

export default App;
